import  { motion} from 'framer-motion'

import image2 from './Disenioyproduccion2.jpg'
import image3 from './Disenioyproduccion3.jpg'
import image4 from './Administracion-Gerencia.jpg'
import image6 from './Administracion - Finanzas.jpg'
import image5 from './Disenioyproduccion1.jpg'



const ContactoImagenes = () => {

    return (
   <div className='grid grid-cols-1 md:grid-cols-3 gap-4  m-16 max-w-8xl items-center content-center justify-center '>
     
        <div >
            <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} whileHover={{scale:1.4}} viewport={{ once: true }}src={image4} alt="" srcset="" className='h-auto w-full max-w-full rounded-xl shadow' />
        </div>
        <div>
            <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} whileHover={{scale:1.4}} viewport={{ once: true }}src={image3} alt="" srcset="" className='h-auto w-full max-w-full rounded-xl shadow' />
        </div>
        <div>
            <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} whileHover={{scale:1.4}} viewport={{ once: true }}src={image2} alt="" srcset="" className='h-auto w-full max-w-full rounded-xl shadow' />
        </div>
        <div>
            <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} whileHover={{scale:1.4}} viewport={{ once: true }}src={image6} alt="" srcset="" className='h-auto w-full max-w-full rounded-xl shadow' />
        </div>
        <div>
            <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} whileHover={{scale:1.4}} viewport={{ once: true }}src={image5} alt="" srcset="" className='h-auto w-full max-w-full rounded-xl shadow' />
        </div>
    </div>
    )
}

export default ContactoImagenes