export const MarcasData = [ 
{
    nombre:'Broer',
    imagen:require('../../images/marcas/broer.png')
},
{
    nombre:'Cheeky',
    imagen:require('../../images/marcas/cheeky.png')
},
{
    nombre:'Gaudi',
    imagen:require('../../images/marcas/gaudi.png')
},
{
    nombre:'Grisino',
    imagen:require('../../images/marcas/grisino.png')
},
{
    nombre:'Julien',
    imagen:require('../../images/marcas/julien.png')
},
{
    nombre:'Kosiuko',
    imagen:require('../../images/marcas/kosiuko.png')
},

{
    nombre:'Mora',
    imagen:require('../../images/marcas/mora.png')
},
{
    nombre:'Paula',
    imagen:require('../../images/marcas/paula.png')
},
{
    nombre:'Tascani',
    imagen:require('../../images/marcas/tascani.png')
},
{
    nombre:'Wanama',
    imagen:require('../../images/marcas/wanama.png')
},
{
    nombre:'MSM textil',
    imagen:require('../../images/marcas/msm.jpg')
},
{
    nombre:'Rapport Grazziano',
    imagen:require('../../images/marcas/rapportGraziano.jpg')
}




]