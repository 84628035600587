import "./index.css";

import App from "./App";
import {
  BrowserRouter,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";




ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <App>
     
      </App>
  </BrowserRouter>
);