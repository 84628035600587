export const  estampadosSvg = [
    { svg: require("./10.924-01.svg").default, nombre:'1092401' },
    { svg: require("./10.944 Bear Baby-01.svg").default, nombre:'10944BearBaby01' },
    { svg: require( "./10.964 langostinos-01.svg").default, nombre:'10964Langostinos01' },
    { svg: require("./10.966-01.svg").default, nombre:"1096601" },
    { svg: require("./10.967-01.svg").default , nombre:"1096701"},
    { svg: require("./11.002-01.svg").default , nombre:"1100201"},
    { svg: require("./11.020-01.svg").default , nombre:"1102001"},
    { svg: require("./11.035-01.svg").default , nombre:"1103501"},
    { svg: require("./11.047 aviones-01.svg").default , nombre:"11047Aviones01"},
    { svg: require("./11.048 perritos-01.svg").default , nombre:"11048Perritos01"},
    { svg: require("./11.060-01.svg").default , nombre:"1106001"},
    { svg: require("./11.067 cruces-01.svg").default , nombre:"11067Cruces01"},
    { svg: require("./11.068  Dinos-01.svg").default , nombre:"11068Dinos01"},
    { svg: require("./11.087 Rock-01.svg").default , nombre:"11087Rock01"},
    { svg: require("./11.099-01.svg").default , nombre:"1109901"},
    { svg: require("./11.105 robot-01.svg").default , nombre:"11105Robot01"},
    { svg: require("./11.126 surf.svg").default , nombre:"11126Surf"},
    { svg: require("./11.199-01.svg").default , nombre:"1119901"},
    { svg: require("./11.323 ondas-01.svg").default , nombre:"11323Ondas01"},
    { svg: require("./11.326 Hanna-01.svg").default , nombre:"11326Hanna01"},
    { svg: require("./11.335 Mariposas  nuevo-01.svg").default , nombre:"11335MariposasNuevo01"},
    { svg: require("./11.337 Leyendas-01.svg").default , nombre:"11337Leyendas01"},
    { svg: require("./11.338 Female-01.svg").default , nombre:"11338Female01"},
    { svg: require("./11.354 Benicio-01.svg").default , nombre:"11354Benicio01"},
    { svg: require("./11.373-01.svg").default , nombre:"1137301"},
    { svg: require("./11.373b-01.svg").default , nombre:"11373B01"},
    { svg: require("./11.374-01.svg").default , nombre:"1137401"},
    { svg: require("./11.385  letras chinas-01.svg").default , nombre:"11385LetrasChinas01"},
    { svg: require("./11.391 Soles y Lunas-01.svg").default , nombre:"11391SolesYLunas01"},
    { svg: require("./11.400-01.svg").default , nombre:"1140001"},
    { svg: require("./11.412 Cebra-01.svg").default , nombre:"11412Cebra01"},
    { svg: require("./11.417 Hawaii nene_Mesa de trabajo 1.svg").default , nombre:"11417HawaiiNeneMesaDeTrabajo1"},
    { svg: require("./11.419 conejos-01.svg").default , nombre:"11419Conejos01"},
    { svg: require("./11.422 Jirafa-01.svg").default , nombre:"11422Jirafa01"},
    { svg: require("./11.423 pintitas-01.svg").default , nombre:"11423Pintitas01"},
    { svg: require("./11.424 Tigres-01.svg").default , nombre:"11424Tigres01"},
    { svg: require("./11.432-01.svg").default , nombre:"1143201"},
    { svg: require("./11.434 glitter unic-01.svg").default , nombre:"11434GlitterUnic01"},
    { svg: require("./11.436 corazones-01.svg").default , nombre:"11436Corazones01"},
    { svg: require("./11.437 Snoopy-01.svg").default , nombre:"11437Snoopy01"},
    { svg: require("./11.440 Gloria-01.svg").default , nombre:"11440Gloria01"},
    { svg: require("./11.446-01.svg").default , nombre:"1144601"},
    { svg: require("./11.449-01.svg").default , nombre:"1144901"},
    { svg: require("./11028-01.svg").default , nombre:"1102801"},
    { svg: require("./11037-01.svg").default , nombre:"1103701"},
    { svg: require("./11080-01.svg").default , nombre:"1108001"},
    { svg: require("./11124-01.svg").default , nombre:"1112401"},
    { svg: require("./11142-01.svg").default , nombre:"1114201"},
    { svg: require("./11208-01.svg").default , nombre:"1120801"},
    { svg: require("./11223-01.svg").default , nombre:"1122301"},
    { svg: require("./11252-01.svg").default , nombre:"1125201"},
    { svg: require("./11280-01.svg").default , nombre:"1128001"},
    { svg: require("./11328 cheeky-01.svg").default , nombre:"11328Cheecky01"},
    { svg: require("./11334-01.svg").default , nombre:"1133401"},
    { svg: require("./11335-01.svg").default , nombre:"1133501"},
    { svg: require("./11338 Female-01.svg").default , nombre:"11338Female01"},
    { svg: require("./11344-01.svg").default , nombre:"1134401"},
    { svg: require("./11345-01.svg").default , nombre:"1134501"},
    { svg: require("./11354-01.svg").default , nombre:"1135401"},
    { svg: require("./11355 Leo-01.svg").default , nombre:"11355Leo01"},
    { svg: require("./11361 Palms-01.svg").default , nombre:"11361Palms01"},
    { svg: require("./11362 Clouds-01.svg").default , nombre:"11362Clouds01"},
    { svg: require("./11372 Vichy-01.svg").default , nombre:"11372Vichy01"},
    { svg: require("./11385  letras chinas-01.svg").default , nombre:"11385LetrasChinas01"},
    { svg: require("./11873-01.svg").default , nombre:"1187301"},
    { svg: require("./20.961-01.svg").default , nombre:"2096101"},
    { svg: require("./21.065 Cosmic-01.svg").default , nombre:"21065Cosmic01"},
    { svg: require("./21.093-01.svg").default , nombre:"2109301"},
    { svg: require("./21.098-01.svg").default , nombre:"2109801"},
    { svg: require("./21.304 Manchas  de vaca-01.svg").default , nombre:"21304ManchasDeVaca01"},
    { svg: require("./21.360 Navy-01.svg").default , nombre:"21360Navy01"},
    { svg: require("./21.420 Flamencos-01.svg").default , nombre:"21420Flamencos01"},
    { svg: require("./21041-01.svg").default , nombre:"2104101"},
    { svg: require("./21125-01.svg").default , nombre:"2112501"},
    { svg: require("./21358  Sunny-01.svg").default , nombre:"21358Sunny01"},
    { svg: require("./21363-01.svg").default , nombre:"2136301"},
    { svg: require("./21382 Finn-01.svg").default , nombre:"21382Finn01"},
    { svg: require("./21384 Paradise cheeky-01.svg").default , nombre:"21384ParadiseCheeky01"},
    { svg: require("./21393 naranjo-01.svg").default , nombre:"21393Naranjo01"},
    { svg: require("./30.965 Dino-01.svg").default , nombre:"30965Dino01"},
    { svg: require("./31.104 Tina-01.svg").default , nombre:"31104Tina01"},
    { svg: require("./31.272 flor-01.svg").default , nombre:"31272Flor01"},
    { svg: require("./31183-01.svg").default , nombre:"3118301"},
    { svg: require("./41.333-01.svg").default , nombre:"4133301"},
    { svg: require("./41.404 Dino-01.svg").default , nombre:"41404Dino01"},
    { svg: require("./41.411 FIORELA-01.svg").default , nombre:"41411Fiorela01"},
    { svg: require("./41187-01.svg").default , nombre:"4118701"},
    { svg: require("./41351-01.svg").default , nombre:"4135101"},
    { svg: require("./41357Animal-01.svg").default , nombre:"41357Animal01"},
    { svg: require("./50.970 candy-01.svg").default , nombre:"50970Candy01"},
    { svg: require("./61.448 Ben-01.svg").default , nombre:"61448Ben01"},
    { svg: require("./71.379  Martina-01.svg").default , nombre:"71379Martina01"},
    { svg: require("./81.383 Lupita-01.svg").default , nombre:"81383Lupita01"},
   
  ];


  